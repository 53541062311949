
import { Component, Vue } from 'vue-property-decorator'
import WorkOrderClose from './Close.vue'
import WorkOrderTransfer from './Transfer.vue'
import WorkOrderFeedback from './Feedback.vue'
import WorkOrderAudit from './Audit.vue'
import { FileInfo } from '../../types/common'
import { HandleDetail, OrderDetail } from '../../types/newOrder'
@Component({
  components: { WorkOrderClose, WorkOrderTransfer, WorkOrderFeedback, WorkOrderAudit },
  filters: {
    imgListFormat (list: Array<FileInfo>) {
      console.log(list)
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class Detail extends Vue {
  private activeNames = ['1', '2', '3']
  private info: OrderDetail | {} = {}
  private handleTypeList = [{
    icon: '#iconicon_zhuanban',
    class: 'transfer',
    status: '转办'
  }, {
    icon: '#iconicon_fankui',
    class: 'feedback',
    status: '反馈'
  }, {
    icon: '#iconicon_shenhe',
    class: 'audit',
    status: '审核'
  }, {
    icon: '#iconicon_guanbi',
    class: 'close',
    status: '关闭'
  }]

  private handleList: Array<HandleDetail> = []
  private closeShow = false
  private transferShow = false
  private feedbackShow = false
  private auditShow = false

  get hasHandle () {
    return this.$route.name === 'orderRecordDetail'
  }

  get currentUserId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.newOrder.selectFlowOrderByOrderId, {
      orderId: this.$route.params.id
    }).then(res => {
      this.info = res || {}
      this.handleList = res.handleList || []
    })
  }

  // 待反馈，待返工，待审核
  onHandle () {
    if (['1', '3'].includes((this.info as OrderDetail).orderStatus)) {
      this.feedbackShow = true
    } else {
      this.auditShow = true
    }
  }
}
